import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';

export const signInGuard: CanActivateFn = () => {
  const auth = inject(AuthService);
  const router = inject(Router);
  const hasToken = !!auth.getToken();
  if (!hasToken) {
    router.navigate(['/auth', 'sign-in']);
  }
  return hasToken;
};
