import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Api, FilterParams } from '../models/api';

@Injectable({
  providedIn: 'root',
})
export abstract class ApiService<T, F> {
  abstract api: string;
  constructor(private _httpClient: HttpClient) {}

  find(params: FilterParams<F> = {}) {
    return this._httpClient.get<Api<T>>(this.api, { params });
  }

  create(payload: Partial<T>) {
    return this._httpClient.post<T>(this.api, payload);
  }

  update(id: number, payload: T) {
    return this._httpClient.patch<T>(`${this.api}/${id}`, payload);
  }

  findById(id: number) {
    return this._httpClient.get<T>(`${this.api}/${id}`);
  }

  delete(id: number) {
    return this._httpClient.delete<T>(`${this.api}/${id}`);
  }
}
